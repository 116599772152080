import React, { useEffect, useState } from 'react';
import axios from 'axios';
import loaderGif from './loader.gif';
import Header from './Header.js';
import Footer from './footer.js';
import bannerGif1 from './BannerImg1.webp';
import bannerGif2 from './BannerImg2.webp';
import AdvancedSearch from './AdvanceSearch.js';
import './App.css';

function App() {

  const [displayLists, setListDisplay] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [searchPrompt, setSearchPrompt] = useState('');
  const [responseList, setResponseList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(-1);

  const callChatGPTAPI = async (inputText) => {
    try {
      const apiUrl = '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';

      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          messages: [
            { role: "system", content: "You will be provided with a prompt or topic or message, and your task is to generate the best suitable caption for social media posts that attracts the users." },
            { role: 'user', content: inputText },
          ],
          model: "gpt-3.5-turbo"
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiUrl}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        const data = await JSON.parse(response.request.responseText);
        var result = extractContent(data);
        populateListing(result);
        console.log('API response:', result);
      } else {
        console.error('Failed to call API:', response);
        alert(response.error.message);
      }
    } catch (error) {
      console.error('Error calling API:', error);
      alert(error.message);
    }
  };

  const handleAdvanceSearch = (searchPrompt) => {
    console.log(searchPrompt);
    setSearchPrompt(searchPrompt);
  }

  useEffect(() => {
    handleSubmit();
  }, [searchPrompt]); // This will log the updated inputValue whenever it changes

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  }

  const handleSubmit = async () => {
    if (!inputValue) {
      return;
    }
    console.log('Submitted text:', inputValue + searchPrompt);
    setIsLoading(true);

    // Here perform the apiCall
    await callChatGPTAPI(inputValue + searchPrompt);
    //await new Promise(resolve => setTimeout(resolve, 2000));
    setIsLoading(false);

  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const copyToClipboard = async (text, index) => {
    console.log('copyToClipboard', text);
    try {
      await navigator.clipboard.writeText(text);
      setCopied(index);
      setTimeout(() => setCopied(-1), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
      setCopied(-1);
    }
  };

  const extractContent = (jsonResponse) => {
    console.log('extractContent ', jsonResponse);
    if (jsonResponse && jsonResponse.choices && jsonResponse.choices.length > 0) {
      const content = jsonResponse.choices[0].message?.content;
      return content;
    } else {
      return null;
    }
  };

  // Update displayLists when responseList changes
  useEffect(() => {
    // Check if responseList is not empty
    if (responseList.length > 0) {
      setListDisplay(true); // Display listingContainer
    } else {
      setListDisplay(false); // Hide listingContainer
    }
  }, [responseList]);

  function populateListing(response) {
    console.log('populateListing', response);
    setResponseList([...responseList, response]);
  }


  return (
    <div>
      {/* Header Componenet */}
      <Header />

      <div className="s010">
        <form className="form" onSubmit={handleSubmit}>
          <div className="inner-form">
            <div className='bannerImg'>
              {/* <img src={bannerGif1} alt='Instagram Post caption'></img>
              <img src={bannerGif2} alt='Social Media caption'></img> */}
            </div>
            <h1>Welcome To Caption Craft</h1>
            <h3>AI Generated Captions For Your Photos </h3>
            <div className="basic-search">
              <div className="input-field">
                <input id="search" type="text" placeholder="Describe Your Photo/Post/Story" onChange={handleInputChange} onKeyDown={handleKeyPress} />
                {isLoading ? (
                  <div className="icon-wrap" >
                    <img src={loaderGif} alt="Loading CaptionCraft..." style={{ width: '40px', height: '12px' }} />
                  </div>) : (
                  <div className="icon-wrap" onClick={handleSubmit} tabIndex="0" onKeyDown={handleKeyPress} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <circle cx="12" cy="12" r="10" fill="none"></circle><path d="M8 12h8"></path><path d="M12 16l4-4-4-4" fill="none"></path>
                    </svg>
                  </div>)}
                <button type="submit" style={{ display: 'none' }}></button>
              </div>
            </div>

            {/* Advance filters component */}
            <AdvancedSearch CallbackFunc={handleAdvanceSearch} />

            {/* Result List Container */}
            <div style={{ marginBottom: "40px" }}></div>
            {displayLists ?
              <div id="listingContainer" className="result-container" >
                {responseList.map((item, index) => (
                  <div className='row items' key={index}>{item}
                    <span>
                      {copied === index ? (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M5 13l4 4L19 7"></path>
                        </svg>) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" onClick={() => copyToClipboard(item, index)}>
                          <path fillRule="evenodd" clipRule="evenodd" d="M12 3.5C10.8954 3.5 10 4.39543 10 5.5H14C14 4.39543 13.1046 3.5 12 3.5ZM8.53513 3.5C9.22675 2.3044 10.5194 1.5 12 1.5C13.4806 1.5 14.7733 2.3044 15.4649 3.5H17.25C18.9069 3.5 20.25 4.84315 20.25 6.5V18.5C20.25 20.1569 19.1569 21.5 17.25 21.5H6.75C5.09315 21.5 3.75 20.1569 3.75 18.5V6.5C3.75 4.84315 5.09315 3.5 6.75 3.5H8.53513ZM8 5.5H6.75C6.19772 5.5 5.75 5.94772 5.75 6.5V18.5C5.75 19.0523 6.19772 19.5 6.75 19.5H17.25C18.0523 19.5 18.25 19.0523 18.25 18.5V6.5C18.25 5.94772 17.8023 5.5 17.25 5.5H16C16 6.60457 15.1046 7.5 14 7.5H10C8.89543 7.5 8 6.60457 8 5.5Z" fill="currentColor"></path>
                        </svg>
                      )}
                    </span>
                  </div>
                ))}
              </div> : null}

          </div>
        </form>
      </div>

      {/* Footer Componenet */}
      <Footer />
    </div>
  );
}

export default App;

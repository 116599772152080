import React from 'react';
import logo from './SiteLogo.png'; // Import your logo image here
import './App.css';

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="captions">
        <p>Caption Craft</p>
      </div>
    </header>
  );
};

export default Header;

import React, { useState } from 'react';
import Select from 'react-select';
import './App.css';

function AdvancedSearch(props) {

  // select custom style
  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: '20px',
      backgroundColor: '#ccc',
      color: 'black'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'black', // Set your desired color here
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      // Add any other custom styles you need
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '10px'
    }),
    menuList: (provided) => ({
      ...provided,
      color: 'red'
    }),
  };

  const [selectedValues, setSelectedValues] = useState({}); // State to hold selected values

  const expressions = [
    { value: 'introvert', label: 'A Introvert person' },
    { value: 'influencer', label: 'A Influencer' },
    { value: 'sad_feeling', label: 'Your Sad feeling' },
    { value: 'excited', label: 'You\'re Excited' },
    { value: 'funky_caption', label: 'Funky Caption' },
    { value: 'devotional', label: 'Devotional' },
  ];
  const writefor = [
    { value: 'ad', label: 'A Advertisement' },
    { value: 'product', label: 'A Prdouct' },
    { value: 'business', label: 'Business Idea' },
  ];
  const language = [
    { value: 'low', label: 'Low' },
    { value: 'medium', label: 'Medium' },
    { value: 'high', label: 'High' },
  ];

  // Function to handle select change
  const handleSelectChange = (selectedOption, optionName) => {
    const { value } = selectedOption;
    setSelectedValues(prevState => ({
      ...prevState,
      [optionName]: value
    }));
  };

  // Function to handle delete button click
  const handleDeleteButtonClick = () => {
    console.log(selectedValues);
    setSelectedValues({}); // Reset selected values
  };

  // Function to handle delete button click
  const handleSearchButtonClick = (e) => {
    e.preventDefault();// as this is button, it is causing a postback and page is loading again, so to prevent it.
    var searchPrompt = `. Create the caption`;
    searchPrompt += selectedValues["expressions"] ? ` in ${selectedValues["expressions"]} expressions` : ``;
    searchPrompt += selectedValues["writefor"] ? ` for ${selectedValues["writefor"]}` : ``;
    searchPrompt += selectedValues["language"] ? ` and use english language proficiency as ${selectedValues["language"]}` : ``;
    props.CallbackFunc(searchPrompt);
    // return false;
  };

  return (
    <div className="advance-search">
      <span className="desc">ADVANCED FILTERS</span>
      <div className="row" onClick={(e) => e.stopPropagation()}>
        <div style={{ margin: '10px', width: '200px' }}>
          <Select styles={customStyles} options={expressions} placeholder="Express As" optionName="expressions"
            onChange={(selectedOption) => handleSelectChange(selectedOption, "expressions")} />
        </div>
        <div style={{ margin: '10px', width: '200px' }}>
          <Select styles={customStyles} options={writefor} placeholder="Write For" optionName="writefor"
            onChange={(selectedOption) => handleSelectChange(selectedOption, "writefor")} />
        </div>
        <div style={{ margin: '10px', width: '200px' }}>
          <Select styles={customStyles} options={language} placeholder="Language Level" optionName="language"
            onChange={(selectedOption) => handleSelectChange(selectedOption, "language")} />
        </div>
      </div>
      <div className="row third">
        <div class="input-field">
          <div class="result-count">
            <button class="btn-delete" id="delete" onChange={handleDeleteButtonClick}>RESET</button>
          </div>
          <div class="group-btn">
            <button class="btn-search" onClick={handleSearchButtonClick}>SEARCH</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvancedSearch;

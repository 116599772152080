import React from 'react';
import './App.css'; // Import the external CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="inner-container">
        <div className="left-content">
          <p>Caption Craft &copy; {new Date().getFullYear()} All rights reserved.</p>
        </div>
        <div className="right-content">
            <p href='#'>Terms of Services</p>
          {/* <h3>More Info</h3> */}
          <ul>
            {/* <li>About Us</li> */}
            {/* <li>Contact</li> */}
            {/* <li>Terms of Service</li> */}
            {/* Add more links as needed */}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
